function Discord() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.61 9.5C11.61 10.11 11.16 10.61 10.59 10.61C10.03 10.61 9.57 10.11 9.57 9.5C9.57 8.89 10.02 8.39 10.59 8.39C11.16 8.39 11.61 8.89 11.61 9.5ZM6.94 8.39C6.37 8.39 5.92 8.89 5.92 9.5C5.92 10.11 6.38 10.61 6.94 10.61C7.51 10.61 7.96 10.11 7.96 9.5C7.97 8.89 7.51 8.39 6.94 8.39ZM17.5 2.06V20C14.9807 17.7737 15.7864 18.5106 12.86 15.79L13.39 17.64H2.05C0.92 17.64 0 16.72 0 15.58V2.06C0 0.92 0.92 0 2.05 0H15.45C16.58 0 17.5 0.92 17.5 2.06ZM14.65 11.54C14.65 8.32 13.21 5.71 13.21 5.71C11.77 4.63 10.4 4.66 10.4 4.66L10.26 4.82C11.96 5.34 12.75 6.09 12.75 6.09C10.3746 4.78809 7.58422 4.78785 5.28 5.8C4.91 5.97 4.69 6.09 4.69 6.09C4.69 6.09 5.52 5.3 7.32 4.78L7.22 4.66C7.22 4.66 5.85 4.63 4.41 5.71C4.41 5.71 2.97 8.32 2.97 11.54C2.97 11.54 3.81 12.99 6.02 13.06C6.02 13.06 6.39 12.61 6.69 12.23C5.42 11.85 4.94 11.05 4.94 11.05C5.08711 11.153 5.32969 11.2864 5.35 11.3C7.03789 12.2452 9.43547 12.5549 11.59 11.65C11.94 11.52 12.33 11.33 12.74 11.06C12.74 11.06 12.24 11.88 10.93 12.25C11.23 12.63 11.59 13.06 11.59 13.06C13.8 12.99 14.65 11.54 14.65 11.54Z"
        fill="#0E0333"
      />
    </svg>
  );
}

export default Discord;
