function Github() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75666 0.666626C6.71671 0.667684 4.74365 1.39019 3.19029 2.70496C1.63692 4.01973 0.604549 5.84102 0.27776 7.84317C-0.0490288 9.84532 0.351074 11.8978 1.40653 13.6335C2.462 15.3693 4.10398 16.6751 6.0389 17.3176C6.46577 17.3968 6.62656 17.1323 6.62656 16.9073C6.62656 16.6824 6.61802 16.0301 6.61517 15.3171C4.22468 15.8335 3.71955 14.3083 3.71955 14.3083C3.32968 13.3179 2.7662 13.0576 2.7662 13.0576C1.98645 12.5284 2.82455 12.5384 2.82455 12.5384C3.68825 12.5992 4.14216 13.4198 4.14216 13.4198C4.90769 14.7257 6.15273 14.3479 6.64221 14.1272C6.71904 13.574 6.94245 13.1977 7.18861 12.984C5.27906 12.769 3.27276 12.0361 3.27276 8.76221C3.26092 7.91314 3.5778 7.09204 4.15781 6.46879C4.06959 6.25374 3.77504 5.38504 4.24176 4.20508C4.24176 4.20508 4.96318 3.97588 6.60522 5.08086C8.01365 4.69788 9.49966 4.69788 10.9081 5.08086C12.5487 3.97588 13.2687 4.20508 13.2687 4.20508C13.7368 5.38221 13.4423 6.25091 13.3541 6.46879C13.9359 7.09214 14.2535 7.91467 14.2405 8.76504C14.2405 12.046 12.23 12.769 10.3176 12.9798C10.6249 13.2458 10.8996 13.765 10.8996 14.563C10.8996 15.7061 10.8896 16.6258 10.8896 16.9073C10.8896 17.1351 11.0447 17.4011 11.4801 17.3176C13.4152 16.6751 15.0574 15.369 16.1128 13.6329C17.1683 11.8969 17.5682 9.84411 17.241 7.84178C16.9138 5.83944 15.881 4.01813 14.3271 2.70359C12.7732 1.38905 10.7997 0.667014 8.7595 0.666626H8.75666Z"
        fill="#0E0333"
      />
    </svg>
  );
}

export default Github;
