function Gitbook() {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3001 14.7749C11.6751 14.7749 12.0126 15.0749 12.0126 15.4874C12.0126 15.8624 11.7126 16.1999 11.3001 16.1999C10.9251 16.1999 10.5876 15.8999 10.5876 15.4874C10.5876 15.0749 10.9251 14.7749 11.3001 14.7749ZM22.3251 10.4249C21.9501 10.4249 21.6126 10.1249 21.6126 9.71244C21.6126 9.33744 21.9126 8.99994 22.3251 8.99994C22.7001 8.99994 23.0376 9.29994 23.0376 9.71244C23.0376 10.0874 22.7001 10.4249 22.3251 10.4249ZM22.3251 7.53744C21.1251 7.53744 20.1501 8.51244 20.1501 9.71244C20.1501 9.93744 20.1876 10.1624 20.2626 10.3874L13.1001 14.2124C12.6876 13.6124 12.0126 13.2749 11.3001 13.2749C10.4751 13.2749 9.72505 13.7624 9.35005 14.4749L2.90005 11.0999C2.22505 10.7249 1.70005 9.63744 1.77505 8.58744C1.81255 8.06244 2.00005 7.64994 2.26255 7.49994C2.45005 7.38744 2.63755 7.42494 2.90005 7.53744L2.93755 7.57494C4.66255 8.47494 10.2501 11.3999 10.4751 11.5124C10.8501 11.6624 11.0376 11.7374 11.6751 11.4374L23.2251 5.43744C23.4126 5.36244 23.6001 5.21244 23.6001 4.94994C23.6001 4.61244 23.2626 4.46244 23.2626 4.46244C22.5876 4.16244 21.5751 3.67494 20.6001 3.22494C18.5001 2.24994 16.1001 1.12494 15.0501 0.562442C14.1501 0.0749418 13.4001 0.487442 13.2876 0.562442L13.0251 0.674942C8.26255 3.07494 1.96255 6.18744 1.58755 6.41244C0.950053 6.78744 0.537553 7.57494 0.500053 8.54994C0.425053 10.0874 1.21255 11.6999 2.33755 12.2624L9.16255 15.7874C9.31255 16.8374 10.2501 17.6624 11.3001 17.6624C12.5001 17.6624 13.4376 16.7249 13.4751 15.5249L20.9751 11.4749C21.3501 11.7749 21.8376 11.9249 22.3251 11.9249C23.5251 11.9249 24.5001 10.9499 24.5001 9.74994C24.5001 8.51244 23.5251 7.53744 22.3251 7.53744Z"
        fill="#0E0333"
      />
    </svg>
  );
}

export default Gitbook;
